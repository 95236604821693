import React, { Component, Fragment } from "react";
import { Row, Card, CardTitle, Form, Col, Input, CustomInput, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { uuid } from 'uuidv4';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { ToastProvider } from "react-toast-notifications";
import { loginUser } from "../redux/actions";
import { Colxx } from "../components/common/CustomBootstrap";
import { projectTitle, REST_API_END_POINT, dataEncryptKey, BASE_URL, IG_TOKEN, AGE_WARNING } from "../constants/defaultValues";
import HomeHeader from "../components/common/HomeHeader";
import HomeFooter from "../components/common/HomeFooter";
import ProductSlideList from "../components/front-end/ProductSlideList";
import DealSlideList from "../components/front-end/DealSlideList";
import Rating from "../components/common/FilledRating";

import SwiperCore, { Navigation, Autoplay } from 'swiper';
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import moment from "moment";

import { isMobile, isAndroid } from "react-device-detect";

import MailChimpIntegration from "../components/front-end/MailChimpIntegration";
import { seo } from "../helpers/Utils";
import { SideSection } from '../components/common/landingPage';
import { Divider } from '../components/ui-elements';
import 'react-ig-feed/dist/index.css';

var dataCrypto = new SimpleCrypto(dataEncryptKey);

SwiperCore.use([Navigation, Autoplay]);

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			confirmed: false,
			show_facilities: false,
			facilities: [],
			home_sliders: [],
			categories: [],
			posts: [],
			testimonials: [],
			featured_products: [],
			new_arrival_products: [],
			top_deal_products: [],
			google_reviews: [],
		};
		this.setUserAgeToken = this.setUserAgeToken.bind(this);
		this.loadHomePage = this.loadHomePage.bind(this);
		this.goNextReview = this.goNextReview.bind(this);
		this.goPrevReview = this.goPrevReview.bind(this);

		this.swiperRef = React.createRef();
		seo({
			title: 'Home | ' + projectTitle,
		});
	}

	componentDidMount() {

		document.body.classList.add("background");
		document.body.classList.add("background-main-pages");
		var formData = new FormData();
		formData.append('subscriber_id', 1);

		axios.post(REST_API_END_POINT + 'front-end/get-facilities', formData).then((res) => {
			var data = res.data;

			var facilities = data.facilities;
			this.setState({
				facilities
			});
		}).catch(e => {
			console.log("Addition failed , Error ", e);
		});
		this.loadHomePage();
	}

	loadHomePage() {
		var facility_id = localStorage.getItem('facility_id');
		if (facility_id) {
			facility_id = dataCrypto.decrypt(facility_id);
			var data_upload = new FormData();
			data_upload.append('facility_id', facility_id);
			data_upload.append('user_time', moment().format('HH:mm:00'));
			axios.post(REST_API_END_POINT + 'website/home-page', data_upload).then((res) => {
				if (res.data.status == 1) {
					this.setState({
						home_sliders: res.data.home_sliders,
						categories: res.data.categories,
						posts: res.data.posts,
						testimonials: res.data.testimonials,
						featured_products: res.data.featured_products,
						new_arrival_products: res.data.new_arrival_products,
						top_deal_products: res.data.top_deal_products,
						google_reviews: res.data.google_reviews,
						blocking: false
					});
				} else {
					this.setState({ blocking: false });
				}
			}).catch(e => {
				console.log("Addition failed , Error ", e)
				this.setState({ blocking: false });
			});
		}
	}

	componentWillUnmount() {
		document.body.classList.remove("background");
		document.body.classList.remove("background-main-pages");
	}

	setUserAgeToken(event) {
		event.preventDefault();
		localStorage.setItem('user_age_token', uuid());
		this.setState({ show_facilities: true });
	}

	goNextReview() {
		let swiperRef = this.swiperRef;
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slideNext();
		}
	}

	goPrevReview = () => {
		let swiperRef = this.swiperRef;
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slidePrev();
		}
	};

	render() {
		const { home_sliders, categories, featured_products, new_arrival_products, top_deal_products, posts, testimonials, google_reviews } = this.state;

		const params = {
			//autoHeight: true,
			spaceBetween: 10,
			centeredSlides: true,
			loop: true,
		};

		if (home_sliders.length > 1) {
			params.navigation = {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			}
		}

		const paramsone = {
			spaceBetween: 0,
			centeredSlides: false,
			loop: false,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},

			shouldSwiperUpdate: true,
			rebuildOnUpdate: true,
			breakpoints: {
				640: {
					slidesPerView: 1,
					spaceBetween: 20,
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 0,
				},
			}
		}

		const google_reviews_params = {
			spaceBetween: 40,
			centeredSlides: false,
			loop: false,
			shouldSwiperUpdate: true,
			rebuildOnUpdate: true,
			breakpoints: {
				640: {
					slidesPerView: 1,
					spaceBetween: 40,
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 30,
				},
				1024: {
					slidesPerView: 4,
					spaceBetween: 40,
				},
			}
		}

		const paramstwo = {
			spaceBetween: 0,
			centeredSlides: true,
			loop: posts.length > 3,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			shouldSwiperUpdate: true,
			rebuildOnUpdate: true,
			breakpoints: {
				640: {
					slidesPerView: 1,
					spaceBetween: 0,
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 0,
				},
			}
		}

		const user_age_token = AGE_WARNING ? localStorage.getItem('user_age_token') : uuid();
		const facility_id = localStorage.getItem('facility_id');

		if (user_age_token != null && facility_id != null) {
			return (
				<Fragment>
					<ToastProvider placement="bottom-left">
						<div className="main-pages-layout">
							<main>
								<HomeHeader />
								<div>
									{home_sliders.length ? <Swiper {...params}>
										{home_sliders.map((row, i) => {
											let featured_image = (isMobile || isAndroid) && row.mobile_version != null ? row.mobile_version : row.featured_image;

											let content = '';

											if (row.content != null) {
												content = JSON.parse(JSON.stringify(row.content));
											}
											if (row.button_text != null && row.button_link != null && row.button_text != "" && row.button_link != "") {
												content += `<div class="${row.button_position}"><a class="btn btn-primary btn-md mt-3" style="border-color:${row.button_background_color};background-color:${row.button_background_color};color:${row.button_color}" href="${row.button_link}">${row.button_text}</a></div>`;
											}
											return (<div className="front-home-slider" key={i} >
												<img src={`${BASE_URL}pages/${featured_image}`} />
												<div className="dynamic-page-content" dangerouslySetInnerHTML={{ __html: content }} ></div>
											</div>)
										})}
									</Swiper> : ""}
									<Card className='info-card col-md-8 mx-3 d-none'>
										<Row className='p-3 align-items-center'>
											<Row className='flex-grow-1 align-items-center justify-content-center mx-0 px-2'><h6 className='ml-2 mb-0'>125+ Great Reviews</h6></Row>
											<Divider />
											<Row className='flex-grow-1 align-items-center justify-content-center mx-0 px-2'> <img src={`/assets/img/best-seller.png`} /><h6 className='ml-2 mb-0'>Quality Products</h6></Row>
											<Divider />
											<Row className='flex-grow-1 align-items-center justify-content-center mx-0 px-2'> <img src={`/assets/img/isoglobe.png`} /><h6 className='ml-2 mb-0'>9001:2015 Certified Company</h6></Row>
										</Row>
									</Card>
								</div>
								{/*blog starts */}



								<div className=' landing-root'>

									{/* {posts.length > 0 ?  */}
									<Fragment>
										<div className="container  py-4 mt-5">
											<div className="text-left mb-3 text-uppercase"><h6>About Us</h6></div>
											<div className="row mobile-slide-padding justify-content-center">
												<SideSection
													textLeft={true}
													img={`/assets/img/gym.jpeg`}
												>
													<div>
														<h3 className='h3'>
															MY GYM FITNESS HUB
														</h3>
														<div>
															<p>

																Since 2021 Dec onwards MYGYM Fitness hub is here to help you ,
																Being physically and mentally fit is necessary to live a happy, long life. Exercise is one of the best ways to keep a person healthy. Hence, it is always best to find a workout routine no matter how busy you are. With the numerous diseases that spread today, many individuals realized the essence of workout. Specifically, having a workout routine will give an individual the greatest physical, mental, and social benefits. Accordingly, exercise will help you increase energy levels, reduce chronic disease risk, lose weight, and help improve brain health and memory. With such benefits, you probably will love to do workout routines soon. Luckily, you don’t need to do it yourself as various personal trainers, or professional fitness coaches provide the help you need. And joining fitness classes is just at your fingertips. Today,
															</p>
														</div>
													</div>
													{/* <Row>
														<Col sm={5} className='my-2 '>
															<Button className='btn btn-primary mr-2'>Explore More</Button>
														</Col>
														<Col sm={5} className='my-2 '>
															<Button className='btn btn-primary'>View all products</Button>
														</Col>
													</Row> */}
												</SideSection>
											</div>
										</div></Fragment>
									{/* : ""} */}
									{/*blod ends */}


									{/* {categories.length ? <Fragment><div className="text-center py-4 text-uppercase"><h2>Shop by Category</h2></div>
										<div className="container mb-5">
											<div className="pb-4 row">
												{categories.map((row, i) => {
													return <a key={i} href={"/product-by-category/" + row.category_id} className="col-md-3 col-sm-6 d-block mb-5 text-center">
														<div className="hZfaLM">
															<div className="lazy-img-observer">
																<img
																	className="default-category-img img-responsive"
																	src={BASE_URL + 'products/' + row.thumbnail}
																	alt=""
																/>
															</div>
														</div>
														<div className="h5 mt-2 text-center">{row.name}</div>
														<div className="">{`Shop now >>`}</div>
													</a>
												})}
											</div>
										</div>
									</Fragment> : ""} */}

									{/*top deals start */}
									{top_deal_products.length ? <Fragment><div className="text-center py-4 text-uppercase"><h2>Top Deals</h2></div>
										<div className="shop-area pb-5">
											<div className="container-fluid">
												<div className="row">
													<div className="col-lg-12 shop-bottom-area mobile-slide-padding hide-shop-list-wrap ">
														{/* shop page content default */}
														{top_deal_products.length > 3 || (top_deal_products.length > 1 && (isMobile || isAndroid)) ? <Swiper {...paramsone}>
															{top_deal_products.map((product, i) => {
																return (
																	<DealSlideList
																		spaceBottomClass={"mb-3"}
																		sliderClassName={"swiper-slide col-12 "}
																		product={product}
																		key={i}
																	/>
																);
															})}
														</Swiper> : <div className="row">{top_deal_products.map((product, i) => {
															return (
																<DealSlideList
																	spaceBottomClass={"mb-3"}
																	sliderClassName={"swiper-slide col-12 "}
																	product={product}
																	key={i}
																/>
															);
														})}</div>}
													</div>
												</div>
											</div>
										</div></Fragment> : ""}
									{/*top deals ends */}

									{/*shop start */}
									{featured_products.length ? <Fragment><div className="text-center py-4 text-uppercase"><h2>Our Services</h2></div>
										<div className="shop-area pb-5">
											<div className="container-fluid">
												<div className="row">
													<div className="col-lg-12 shop-bottom-area mobile-slide-padding hide-shop-list-wrap ">
														{/* shop page content default */}
														{featured_products.length > 3 || (featured_products.length > 1 && (isMobile || isAndroid)) ? <Swiper {...paramsone}>
															{featured_products.map(product => {
																return (
																	<ProductSlideList
																		spaceBottomClass={"mb-3"}
																		sliderClassName={"swiper-slide col-12 "}
																		product={product}
																		key={product.id}
																	/>
																);
															})}
														</Swiper> : <div className="row">{featured_products.map(product => {
															return (
																<ProductSlideList
																	spaceBottomClass={"mb-3"}
																	sliderClassName={"col-12 "}
																	product={product}
																	key={product.id}
																/>
															);
														})}</div>}
													</div>
												</div>
											</div>
										</div></Fragment> : ""}
									{/*shop ends */}

									{/* custom section starts */}
									<div>

									</div>
									{/* custom section ends */}



									{/*shop start */}
									{new_arrival_products.length ? <Fragment><div className="text-center py-4 text-uppercase"><h2>New Arrivals</h2></div>
										<div className="shop-area pb-5">
											<div className="container-fluid">
												<div className="row">
													<div className="col-lg-12 shop-bottom-area mobile-slide-padding hide-shop-list-wrap ">
														{/* shop page content default */}
														{new_arrival_products.length > 3 || (new_arrival_products.length > 1 && (isMobile || isAndroid)) ? <Swiper {...paramsone}>
															{new_arrival_products.map(product => {
																return (
																	<ProductSlideList
																		spaceBottomClass={"mb-3"}
																		sliderClassName={"swiper-slide col-12 "}
																		product={product}
																		key={product.id}
																	/>
																);
															})}
														</Swiper> : <div className="row">{new_arrival_products.map(product => {
															return (
																<ProductSlideList
																	spaceBottomClass={"mb-3"}
																	sliderClassName={"swiper-slide col-12 "}
																	product={product}
																	key={product.id}
																/>
															);
														})}</div>}
													</div>
												</div>
											</div>
										</div><hr /></Fragment> : ""}
									{/*shop ends */}


									{/*client starts *}
										{testimonials.length>0 ? <div className="container testimonial-wrapper pb-4 pt-4">
											<div className="row">
												{testimonials.map((row,i)=>{
													return(<div key={i} className="col-lg-3 text-center">
														{row.featured_image!=null ? <img
																	className="default-img img-responsive"
																	src={BASE_URL+'pages/'+row.featured_image}
																	alt=""
														/>:""}
														  <div><p className="pt-2 black text-center">"{row.content}"</p></div>
													</div>)
												})}
											</div>
										</div>:""}
									{/*client ends */}

									{/*client starts */}

									{/* <div className="my-2">
									<div className="text-center pt-4 pb-2"><h1>FOLLOW US ON INSTAGRAM</h1></div>
									<InstagramFeed token={IG_TOKEN} counter="12" />
									</div> */}

									{/* 
									{google_reviews.length > 0 ? <Fragment><div className="container testimonial-wrapper py-5 my-5">
										<div className="text-center pt-4 pb-2"><h1>OUR RECENT REVIEWS</h1></div>
										<Swiper {...google_reviews_params} ref={this.swiperRef}>
											{google_reviews.map((row, i) => {
												return (<div key={i} className="text-center">
													<div><p className="pt-2 black text-center">"{row.comment_text}"</p></div>
													<div className="text-muted mb-2 small-size-rating">
														<Rating total={5} rating={parseInt(row.rating)} interactive={false} />
													</div>
													{row.profile_photo_url != null ? <img
														className="author-img img-responsive"
														src={row.profile_photo_url}
														alt={row.author_name}
													/> : ""}
													<div><p className="pt-2 black h6 text-center">{row.author_name}</p></div>
													<div className="review-type-img">
														<img className="img-responsive" src={row.review_type == 1 ? "/assets/img/google-logo.png" : "assets/img/weedmaps-logo.png"} alt={row.review_type == 1 ? "Google" : "Weedmap"} />
													</div>
												</div>)
											})}
										</Swiper>
										<div className="swiper-button-next swiper-review-next" onClick={this.goNextReview} ></div>
										<div className="swiper-button-prev swiper-review-prev" onClick={this.goPrevReview} ></div>
									</div></Fragment> : ""} */}
									{/*client ends */}
								</div>

								{/*discount ends */}
								{/* <MailChimpIntegration /> */}
								{/*discount ends */}


								<HomeFooter />
							</main>
						</div>
					</ToastProvider>
				</Fragment>
			);
		} else if (user_age_token == null && AGE_WARNING) {
			return (<Fragment>
				<div className="h-100">
					<div className="fixed-background" />
					<main>
						<div className="container">
							<Row className="h-100">
								<Colxx xxs="12" md="6" className="mx-auto my-auto">
									<Card className="auth-card" >
										<div className="form-side" style={{ padding: '50px' }}>
											<NavLink to={`/`} className="white">
												<span className="logo-single" />
											</NavLink>
											<CardTitle className="mb-4 text-center text-uppercase font-weight-bold">
												Please confirm your age to move forward
											</CardTitle>
											<Form onSubmit={this.setUserAgeToken} className="text-center">
												<div className="d-flex justify-content-center align-items-center mt-2">
													<CustomInput
														id="confirm_check21"
														name="confirm_check"
														type="radio"
														value="21"
														onClick={(e) => { this.setState({ confirmed: true }) }}
														label="Yes, I am 21 years or older"
													/>
												</div>
												<div className="d-flex justify-content-center align-items-center mt-2">
													<Button
														color="primary"
														className="btn-shadow"
														size="lg"
														type="submit"
														disabled={!this.state.confirmed}
													>
														{`Enter >>`}
													</Button>
												</div>
											</Form>

										</div>
									</Card>
								</Colxx>
							</Row>
						</div>
					</main>
				</div>
			</Fragment>)
		} else {
			return (<Fragment>
				<div className="h-100">
					<div className="fixed-background" />
					<main>
						<div className="container">
							<Row className="h-100">
								<Colxx xxs="12" md="6" className="mx-auto my-auto">
									<Card className="auth-card" >
										<div className="form-side" style={{ padding: '50px' }}>
											<NavLink to={`/`} className="white">
												<span className="logo-single" />
											</NavLink>
											<CardTitle className="mb-4 text-center text-uppercase font-weight-bold">
												Please Select The Store
											</CardTitle>
											<div className="d-flex justify-content-center align-items-center">
												<div className="text-center w-80 mt-2">
													{this.state.facilities.map(row => {
														return (<Button
															key={row.id}
															block
															color="primary"
															className="btn-shadow"
															size="lg"
															type="button"
															onClick={(e) => {
																localStorage.setItem('facility_id', dataCrypto.encrypt(row.id));
																this.setState({ show_facilities: false });
																this.loadHomePage();
															}}
														>
															{row.name}
														</Button>)
													})}
												</div>
											</div>

										</div>
									</Card>
								</Colxx>
							</Row>
						</div>
					</main>
				</div>
			</Fragment>)
		}
	}
}

const mapStateToProps = ({ authUser }) => {
	const { user, loading } = authUser;
	return { user, loading };
};

export default connect(
	mapStateToProps,
	{
		loginUser
	}
)(Home);
