import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';
import { Row,Card, CardBody, CardTitle, CardSubtitle} from "reactstrap";

import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Breadcrumb from "../../containers/navs/Breadcrumb";

import BestSellers from '../../containers/dashboards/BestSellers';
import EarningGraph from "../../containers/dashboards/EarningGraph";
import TodaysSales from "../../containers/dashboards/TodaysSales";
import TotalEarning from "../../containers/dashboards/TotalEarning";


import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, projectTitle} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";
import { seo } from "../../helpers/Utils";
import { getCurrencySymbol, getCustomerWord } from "../../helpers/Utils";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const customerWord = getCustomerWord();

class Dashboard extends Component {
	constructor(props) {
		super(props);		
		
		let currencySymbol = getCurrencySymbol();
		this.state = {		 		 
			counts: {
				customers:0,
				products:0,
				waste:0,
				orders:0,
			},
			graph_data: null,
			best_sellers: [],
			sales_sums:{},
			currencySymbol
		};
		seo({
			title: projectTitle,
		});
	}
	
	componentDidMount(){  
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'dashboard/get-data',formData).then((res)=>{
			var data = res.data;
		
			if(data.status==1){
				this.setState({
					counts: data.counts,
					graph_data: data.graph,
					best_sellers: data.best_sellers.filter(row=>{ return row.name!="" && row.name!=null; }),
					sales_sums: data.sales_sums,
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
  render() {
    //const {messages} = this.props.intl;
	const { counts, graph_data, best_sellers, sales_sums } = this.state;
    return (
		<Fragment>
			<Row>
				<Colxx xxs="12">
					<Breadcrumb heading="menu.dashboard" match={this.props.match}/>
					<Separator className="mb-5" />
				</Colxx>
			</Row>
		
			<Row className="icon-cards-row mb-3">
				<Colxx xxs="6" sm="4" md="4" lg="4">		  
					<Card >
						<CardBody className="text-center">
							<i className="simple-icon-people" />
							<p className="card-text font-weight-semibold mb-0" style={{height:"auto"}}>{customerWord}s</p>
							<p className="lead text-center">{counts.customers}</p>
						</CardBody>
					</Card>
				</Colxx>			
				<Colxx xxs="6" sm="4" md="4" lg="4">		  
					<Card >
						<CardBody className="text-center">
							<i className="iconsminds-shopping-cart" />
							<p className="card-text font-weight-semibold mb-0" style={{height:"auto"}}>Total Payments</p>
							<p className="lead text-center">{counts.orders}</p>
						</CardBody>
					</Card>
				</Colxx>			
				<Colxx xxs="6" sm="4" md="4" lg="4">		  
					<Card >
						<CardBody className="text-center">
							<i className="fas fa-cubes" />
							<p className="card-text font-weight-semibold mb-0" style={{height:"auto"}}>Services</p>
							<p className="lead text-center">{counts.products}</p>
						</CardBody>
					</Card>
				</Colxx>			
				{/*<Colxx xxs="6" sm="4" md="3" lg="3">		  
					<Card >
						<CardBody className="text-center">
							<i className="simple-icon-trash" />
							<p className="card-text font-weight-semibold mb-0" style={{height:"auto"}}>Waste</p>
							<p className="lead text-center">{counts.waste}</p>
						</CardBody>
					</Card>
				</Colxx>*/}
			</Row>
		
			<Row>
				<Colxx sm="12" xl="7" className="pr-0">
					<div className="mb-3">
					{graph_data!=null ? <EarningGraph data={graph_data.earning_graph} />:""}
					</div>
					<div className="mb-3">
						<BestSellers best_sellers={best_sellers} />	
					</div>
				</Colxx>
				
				<Colxx sm="12" xl="5" className="mb-4">
					<div className="mb-4">
						<Row className="mb-3">
							<Colxx sm="6" >
								<Card >
									<CardBody className="text-center p-3">
										<p className="card-text font-weight-semibold mb-0" style={{height:"auto"}}>Today's Payments</p>
										<p className="lead text-center mb-0">{this.state.currencySymbol}{sales_sums.today!=null ? sales_sums.today:0}</p>
									</CardBody>
								</Card>
							</Colxx>
							<Colxx sm="6" >
								<Card >
									<CardBody className="text-center p-3">
										<p className="card-text font-weight-semibold mb-0" style={{height:"auto"}}>This Week</p>
										<p className="lead text-center mb-0">{this.state.currencySymbol}{sales_sums.week!=null ? sales_sums.week:0}</p>
									</CardBody>
								</Card>
							</Colxx>
						</Row>
						
						<Row className="mb-3">
							<Colxx sm="6" >
								<Card >
									<CardBody className="text-center p-3">
										<p className="card-text font-weight-semibold mb-0" style={{height:"auto"}}>This Month</p>
										<p className="lead text-center mb-0">{this.state.currencySymbol}{sales_sums.month!=null ? sales_sums.month:0}</p>
									</CardBody>
								</Card>
							</Colxx>
							<Colxx sm="6" >
								<Card >
									<CardBody className="text-center p-3">
										<p className="card-text font-weight-semibold mb-0" style={{height:"auto"}}>This Quarter</p>
										<p className="lead text-center mb-0">{this.state.currencySymbol}{sales_sums.quarter!=null ? sales_sums.quarter:0}</p>
									</CardBody>
								</Card>
							</Colxx>
						</Row>
						<Row>
							<Colxx sm="12" >
								<Card >
									<CardBody className="text-center p-3">
										<p className="card-text font-weight-semibold mb-0" style={{height:"auto"}}>This Year</p>
										<p className="lead text-center mb-0">{this.state.currencySymbol}{sales_sums.year!=null ? sales_sums.year:0}</p>
									</CardBody>
								</Card>
							</Colxx>
						</Row>
					</div>
					<div className="mb-4">
						{graph_data!=null ? <TodaysSales data={graph_data.new_earning} />:""}
					</div>
					<div className="mb-4">
						{graph_data!=null ? <TotalEarning data={graph_data.month_earning} />:""}
					</div>
				</Colxx>
			</Row>
		</Fragment>
    );
  }
}

export default injectIntl(Dashboard);