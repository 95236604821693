import React from "react";
import SimpleCrypto from "simple-crypto-js";
import { dataEncryptKey, projectTitle } from "../constants/defaultValues";

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key], B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};


export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
}

export const getCurrentTime=()=>{
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes()
}

export const zeroPad = (input, length) => {
    return (Array(length + 1).join('0') + input).slice(-length);
}

export const getCookie = (key,type='object') => {    
    const data = window.localStorage.getItem(key);
    return data ? (typeof data == 'object' ? data : (type=='object' ? JSON.parse(data):data)) : null;
};

export const setCookie = (key,data) => {
    if(data){
		if(typeof data == 'object'){
			window.localStorage.setItem(key, JSON.stringify(data));
		}else{
			window.localStorage.setItem(key, data);
		}
	}else window.localStorage.removeItem(key);
};


const RGB2Color =(r,g,b)=>{
	return '#'+byte2Hex(r)+byte2Hex(g)+byte2Hex(b);
}

const byte2Hex=(n)=>{
    var nybHexString = "0123456789ABCDEF";

    return String(nybHexString.substr((n >> 4) & 0x0F,1)) + nybHexString.substr(n & 0x0F,1);
}

export const makeColorGradient =(frequency1, frequency2, frequency3, phase1, phase2, phase3, center, width, len) =>{
	var colors = []
	if(len == undefined)
        len = 50;

	if(center == undefined)
		center = 128;

	if(width == undefined)
		width = 127;

	for(var i = 0; i < len; ++i) {
		var red = Math.sin(frequency1 * i + phase1) * width + center;
		var grn = Math.sin(frequency2 * i + phase2) * width + center;
		var blu = Math.sin(frequency3 * i + phase3) * width + center;
		colors.push(RGB2Color(red, grn, blu));
	}
	return colors;
}

export const seo = (data = {}) => {
  data.title = data.title || projectTitle;
  data.metaDescription = data.metaDescription || "";

  document.title = data.title;
  document.querySelector('meta[name="description"]').setAttribute('content', data.metaDescription);
}

export const getCurrencySymbol = (symbol=true) => {
	var dataCrypto = new SimpleCrypto(dataEncryptKey);
	var facility_data = localStorage.getItem('facility_data');
	let currencySymbol="&#8377; ";
	if(facility_data){
		facility_data = dataCrypto.decrypt(facility_data);
		if(typeof facility_data.default_currency!=='undefined'){
			facility_data.default_currency = JSON.parse(facility_data.default_currency);
			currencySymbol = facility_data.default_currency.symbol;
		}
	}
	if(symbol){
		return <span dangerouslySetInnerHTML={{ __html: currencySymbol }} />;
	}else{
		return currencySymbol;
	}
}

export const getCurrencyCode = () => {
	var dataCrypto = new SimpleCrypto(dataEncryptKey);
	var facility_data = localStorage.getItem('facility_data');
	let currencyCode="USD";
	if(facility_data){
		facility_data = dataCrypto.decrypt(facility_data);
		if(typeof facility_data.default_currency!=='undefined'){
			facility_data.default_currency = JSON.parse(facility_data.default_currency);
			currencyCode = facility_data.default_currency.code;
		}
	}
	return currencyCode;
}

export const getCustomerWord = () => {
	var dataCrypto = new SimpleCrypto(dataEncryptKey);
	var facility_data = localStorage.getItem('facility_data');
	let customerWord="Customer";
	if(facility_data){
		facility_data = dataCrypto.decrypt(facility_data);
		if(typeof facility_data.patient_field_name!=='undefined'){
			customerWord = facility_data.patient_field_name;
		}
	}
	return customerWord;
}