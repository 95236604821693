import React, { Component, Fragment } from "react";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, FormGroup, Input, Badge, DropdownMenu, DropdownItem, CustomInput } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import ReactTable from "react-table";
import swal from 'sweetalert';
import { getCustomerWord } from "../../../helpers/Utils";

import InvoiceModal from "../../../containers/pointofsale/InvoiceModal";
import ViewOrderModal from "../../../containers/pointofsale/ViewOrderModal";
import ApproveOrderModal from "../../../containers/pointofsale/ApproveOrderModal";
import EditInvoiceModal from "../../../containers/pointofsale/EditInvoiceModal";
import VoidOrderModal from "../../../containers/pointofsale/VoidOrderModal";
import CompleteOrderModal from "../../../containers/pointofsale/CompleteOrderModal";
import RefundOrderModal from "../../../containers/pointofsale/RefundOrderModal";
import PrintLabelModal from "../../../containers/pointofsale/PrintLabelModal";

import { REST_API_END_POINT, dataEncryptKey, JQUERY_MODULE, CUSTOMER_ORDER_STATUS } from "../../../constants/defaultValues";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";

const dataCrypto = new SimpleCrypto(dataEncryptKey);
const customerWord = getCustomerWord();

export default class AllOrders extends Component {

	constructor(props) {
		super(props);

		this.state = {
			orders: [],
			search: '',
			order_id: 0,
			modalOpenViewOrder: false,
			modalOpenInvoice: false,
			modalOpenApproveOrder: false,
			modalOpenEditInvoice: false,
			modalOpenVoidOrder: false,
			modalOpenCompleteOrder: false,
			modalOpenRefundOrder: false,
			modalOpenPrintLabel: false,
			selectAll: 0,
			selectedIds: [],
			user_privileges: [],
		};

		this.toggleModalViewOrder = this.toggleModalViewOrder.bind(this);
		this.toggleModalInvoice = this.toggleModalInvoice.bind(this);
		this.toggleModalApproveOrder = this.toggleModalApproveOrder.bind(this);
		this.toggleModalEditInvoice = this.toggleModalEditInvoice.bind(this);
		this.toggleModalCompleteOrder = this.toggleModalCompleteOrder.bind(this);
		this.toggleModalVoidOrder = this.toggleModalVoidOrder.bind(this);
		this.toggleModalPrintLabel = this.toggleModalPrintLabel.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.toggleSelectAll = this.toggleSelectAll.bind(this);
		this.cancelOrders = this.cancelOrders.bind(this);
		this.activeItem = this.activeItem.bind(this);
		this.makePayment = this.makePayment.bind(this);
		this.voidOrder = this.voidOrder.bind(this);
	}

	componentDidMount() {
		this.dataListRender();
		var user_id = localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id', user_id);

		axios.post(REST_API_END_POINT + '/dashboard/get-user-privileges', formData).then((res) => {
			if (res.data.status == 1) {
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => {
			console.log("Addition failed , Error ", e);
		});
	}

	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id = dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id', facility_id);

		axios.post(REST_API_END_POINT + 'point-of-sale/all-orders', formData).then((res) => {
			var data = res.data;

			if (data.orders) {
				var orders = data.orders;
				orders = orders.map(row => {
					if (row.delivery_date == '0') {
						row.delivery_date = '';
					} else {
						row.delivery_date = moment(row.delivery_date * 1000).format('MM/DD/YYYY HH:mm');
					}
					if (row.return_amount != 0 && row.refund == 0) {
						row.payment_status_text = ' Partially Refunded';

					} else if (row.refund == 1) {
						row.payment_status_text = 'Refunded';
					}

					else if (row.payment_status == 1 && row.refund == 0) {

						row.payment_status_text = 'Paid';
					}
					else {
						row.payment_status_text = 'Unpaid';
					}

					if (row.type_of_purchase == 1) {
						row.type_of_purchase_text = 'Shop';
					} else if (row.type_of_purchase == 2) {
						row.type_of_purchase_text = 'POS';
					} else {
						row.type_of_purchase_text = '';
					}

					const order_status = CUSTOMER_ORDER_STATUS.find(r => { return r.value == row.status });
					if (order_status != null) {
						row.status_text = order_status.label;
						row.status_badge = <Badge color={order_status.badge} style={{ whiteSpace: 'break-spaces' }} >{order_status.label}</Badge>;
					} else {
						row.status_text = "";
						row.status_badge = "";
					}

					return row;
				})
				this.setState({
					orders: orders,
				});
			} else {
				this.setState({
					orders: [],
				});
			}
		}).catch(e => {
			console.log("Addition failed , Error ", e);
		});
	}


	sendEmail() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id = dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		// formData.append('order_id',this.props.order_id);
		formData.append('facility_id', facility_id);
		// this.setState({ blocking: true });
		axios.post(REST_API_END_POINT + 'point-of-sale/send-payment-reminder', formData).then((res) => {
			var data = res.data;
			if (data.status == 1) {
				swal("Success!", data.msg, "success");
				
			}
			// else{				
			// 	swal("Warning!","There is no such order","warning");
			// }					
		}).catch(e => {
			console.log("Addition failed , Error ", e);
		});
	}

	toggleModalViewOrder = (e, order_id = 0) => {
		this.setState({
			order_id: parseInt(order_id),
			modalOpenViewOrder: !this.state.modalOpenViewOrder
		});
	};

	toggleModalInvoice = (e, order_id = 0) => {
		this.setState({
			order_id: parseInt(order_id),
			modalOpenInvoice: !this.state.modalOpenInvoice
		});
	};

	toggleModalApproveOrder = (e, order_id = 0) => {
		this.setState({
			order_id: parseInt(order_id),
			modalOpenApproveOrder: !this.state.modalOpenApproveOrder
		});
	};

	toggleModalEditInvoice = (e, order_id = 0) => {
		this.setState({
			order_id: parseInt(order_id),
			modalOpenEditInvoice: !this.state.modalOpenEditInvoice
		});
	};

	toggleModalCompleteOrder = (e, order_id = 0) => {
		this.setState({
			order_id: parseInt(order_id),
			modalOpenCompleteOrder: !this.state.modalOpenCompleteOrder
		});
	};

	toggleModalRefundOrder = (e, order_id = 0) => {
		this.setState({
			order_id: parseInt(order_id),
			modalOpenRefundOrder: !this.state.modalOpenRefundOrder
		});
	};

	toggleModalVoidOrder = (e, order_id = 0) => {
		this.setState({
			order_id: parseInt(order_id),
			modalOpenVoidOrder: !this.state.modalOpenVoidOrder
		});
	};

	toggleModalPrintLabel = (e, order_id = 0) => {
		this.setState({
			order_id: parseInt(order_id),
			modalOpenPrintLabel: !this.state.modalOpenPrintLabel
		});
	};

	activeItem = (e, order_id) => {
		let selectedIds = this.state.selectedIds;
		let selectAll = this.state.selectAll;
		if (e.target.checked) {
			selectedIds.push(order_id);

		} else {
			var x = selectedIds.indexOf(order_id);
			if (x >= 0) {
				selectedIds.splice(x, 1);
			}
		}
		if (selectedIds.length != 0 && selectedIds.length == this.state.orders.filter(x => { return x.status != 3 && x.status != 4 && x.status != 5 }).length) {
			selectAll = 1;
		} else {
			selectAll = 0;
		}
		this.setState({ selectedIds, selectAll });
	}

	toggleSelectAll() {
		let newSelected = [];
		if (this.state.selectAll === 0) {
			this.state.orders.forEach(x => {
				if (x.status != 3 && x.status != 4 && x.status != 5) {
					newSelected.push(x.order_id);
				}
			});
		}
		this.setState({
			selectedIds: newSelected,
			selectAll: this.state.selectAll === 0 ? 1 : 0
		});
	}

	cancelOrders = (e, selectedIds) => {
		if (selectedIds.length == 0) {
			swal("Warning!", "Please select at least one order", "warning");
			return;
		}

		var msg = "Cancel these orders?";
		var success_msg = "The orders has been canceled.";
		var failure_msg = "Failed to cancel orders";

		swal({
			title: "Are you sure?",
			text: msg,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					document.getElementById("cancel-orders").disabled = true;
					var facility_id = localStorage.getItem('facility_id');
					facility_id = dataCrypto.decrypt(facility_id);

					var user_id = localStorage.getItem('user_id');
					var formData = new FormData();
					formData.append('order_ids', JSON.stringify(selectedIds));
					formData.append('user_id', user_id);
					formData.append('facility_id', facility_id);

					axios.post(REST_API_END_POINT + 'point-of-sale/cancel-orders', formData).then((res) => {
						var data = res.data;

						document.getElementById("cancel-orders").disabled = false;
						if (data.status == 1) {
							this.dataListRender();
							swal("Success", success_msg, "success");
						} else {
							swal("Failed", failure_msg, "warning");
						}
						this.props.dataListRender();
					}).catch(e => {
						console.log("Addition failed , Error ", e);
						document.getElementById("cancel-orders").disabled = false;
					});

				} else {
					swal("Your operation is canceled!");
				}
			});
	}

	makePayment = (e, order_id) => {
		swal({
			title: "Are you sure?",
			text: "Mark this order as paid?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					window.location = "/app/point-of-sale/process-payment/" + order_id;
					/*var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					
					var user_id = localStorage.getItem('user_id');
					var formData = new FormData();
					formData.append('order_id',order_id);
					formData.append('user_id',user_id);
					formData.append('facility_id',facility_id);
					
					axios.post(REST_API_END_POINT+'point-of-sale/make-payment',formData).then((res)=>{
						var data = res.data;
					
						if(data.status==1){
							this.dataListRender();
							swal("Success",data.msg,"success");
						}else{
							swal("Failed",data.msg,"warning");
						}
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
					});*/

				} else {
					swal("Your operation is canceled!");
				}
			});
	}

	editOrder = (e, order_id) => {
		swal({
			title: "Are you sure?",
			text: "You want adjust this order?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					window.location = "/app/point-of-sale/edit-order/" + order_id;
				} else {
					swal("Your operation is canceled!");
				}
			});
	}

	voidOrder = (e, order_id) => {
		swal({
			title: "Are you sure?",
			text: "Mark this order as void?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					this.toggleModalVoidOrder(e, order_id);
				} else {
					swal("Your operation is canceled!");
				}
			});
	}


	render() {
		let data = this.state.orders;
		if (this.state.search) {
			data = data.filter(row => {
				if (row.vendor_name == null) {
					row.vendor_name = '';
				}
				return row.order_number.includes(this.state.search) || row.vendor_name.toLowerCase().includes(this.state.search) || row.product_barcodes.toLowerCase().includes(this.state.search) || row.order_barcode.toLowerCase().includes(this.state.search) || row.payment_mode.includes(this.state.search) || row.payment_status_text.includes(this.state.search) || row.type_of_purchase_text.includes(this.state.search) || row.status_text.includes(this.state.search)
			})
		}

		const dataTableColumns = [
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll === 1} />,
				accessor: "id",
				width: 50,
				sortable: false,
				Cell: (rowInfo) => {
					if (rowInfo.original.status != 3 && rowInfo.original.status != 4 && rowInfo.original.status != 5) {
						return (<FormGroup>
							<CustomInput type="checkbox" id={"coupon_" + rowInfo.original.order_id} onChange={event => this.activeItem(event, rowInfo.original.order_id)} checked={this.state.selectedIds.includes(rowInfo.original.order_id)} />
						</FormGroup>);
					} else {
						return "";
					}
				}
			},
			{
				Header: "ID",
				accessor: "order_number",
				width: 70,
				Cell: props => <p className="list-item-heading mb-0">{props.value}</p>
			},
			{
				Header: customerWord + " Name",
				accessor: "vendor_name",
				Cell: props => <p className="text-muted mb-0">{props.value}</p>
			},
			{
				Header: " Date",
				accessor: "timestamp",
				width: 90,
				Cell: props => <p className="text-muted mb-0">{moment(props.value * 1000).format('MM/DD/YYYY')}</p>
			},
			/*{
				Header: "Delivery Date",
				accessor: "delivery_date",
				width:100,
				Cell: props => <p className="text-muted mb-0">{props.value}</p>
			},*/
			{
				Header: "Payment Mode",
				accessor: "payment_mode",
				Cell: props => <p className="text-muted mb-0">{props.original.payment_status == 1 ? props.value : ""}</p>
			},
			{
				Header: "Payment Status",
				accessor: "payment_status_text",
				Cell: props => <p className="text-muted mb-0">{props.value}</p>
			},
			{
				Header: "Status",
				accessor: "status_badge",
				Cell: props => props.value
			},
			/*{
				Header: "Source",
				accessor: "type_of_purchase_text",
				Cell: props => props.value
			},*/
			{
				Header: "Options",
				accessor: "order_id",
				Cell: props => <Fragment>
					<div className="action-btns p-0">
						<ItemDropdown item={<DropdownMenu >
							<DropdownItem className="cursor-pointer" onClick={(e) => { this.toggleModalViewOrder(e, props.value) }}>
								<span className="d-inline-block">View</span>
							</DropdownItem>
							{props.original.payment_status == 1 ? <DropdownItem className="cursor-pointer" onClick={(e) => { this.toggleModalInvoice(e, props.value) }} >
								<span className="d-inline-block">Invoice</span>
							</DropdownItem> : ""}
							{props.original.status > 0 && props.original.payment_status == 1 ? <DropdownItem className="cursor-pointer" onClick={(e) => { this.toggleModalPrintLabel(e, props.value) }} >
								<span className="d-inline-block">Print Labels</span>
							</DropdownItem> : ""}
							{props.original.status != 4 ? <DropdownItem className="cursor-pointer" onClick={(e) => { this.toggleModalEditInvoice(e, props.value) }} >
								<span className="d-inline-block" >Edit Invoice</span>
							</DropdownItem> : ""}
							{props.original.payment_status == 0 && props.original.status >= 1 && props.original.status != 4 && props.original.status != 3 && props.original.refund != 1 ? <DropdownItem className="cursor-pointer" onClick={(e) => { this.editOrder(e, props.value) }}  >
								<span className="d-inline-block" >Adjust Order</span>
							</DropdownItem> : ""}
							{/* {props.original.payment_status==0&&props.original.status>=1&&props.original.status!=4 ? <DropdownItem className="cursor-pointer" onClick={(e)=>{ this.makePayment(e,props.value) }}  >
									<span className="d-inline-block" >Make Payment</span>
								</DropdownItem>:""} */}
							{props.original.status == 0 && this.state.user_privileges.indexOf(133) !== -1 ? <DropdownItem className="cursor-pointer" onClick={(e) => { this.toggleModalApproveOrder(e, props.value) }} >
								<span className="d-inline-block" >Approve</span>
							</DropdownItem> : ""}
							{/* {props.original.status==1 && props.original.payment_status!=0 && this.state.user_privileges.indexOf(133)!==-1 ? <DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalCompleteOrder(e,props.value) }} >
									<span className="d-inline-block" >Complete</span>
								</DropdownItem>:""} */}
							{/* {props.original.payment_mode=='Hypur' && props.original.refund==0 && this.state.user_privileges.indexOf(133)!==-1? <DropdownItem className="cursor-pointer"  >
									<span className="d-inline-block" >Refund</span>
								</DropdownItem>:""} */}
							{/* {this.state.user_privileges.indexOf(133)!==-1 && props.original.fully_returned==0 && props.original.status==3 ? <DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalRefundOrder(e,props.value) }}  >
									<span className="d-inline-block" >Refund Order</span>
								</DropdownItem>:""} */}
							{/*this.state.user_privileges.indexOf(133)!==-1 ? <DropdownItem className="cursor-pointer" onClick={(e)=>{ this.voidOrder(e,props.value) }}  >
									<span className="d-inline-block">Void</span>
								</DropdownItem>:""*/}
						</DropdownMenu>} />
					</div>
					{this.state.user_privileges.indexOf(132) !== -1 && props.original.fully_returned != 1 ? <div className="mt-2">
						{props.original.payment_status == 0 && props.original.status >= 1 && props.original.status != 4 ? <Button color="primary" size="sm" className="cursor-pointer" onClick={(e) => { this.makePayment(e, props.value) }}  >
							<span className="d-inline-block d-none" >Make Payment</span>
						</Button> : ""}
					</div> : ""}
				</Fragment>
			}
		];
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.all-orders" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>

				<Row>
					<Colxx md="12">
						<Card>
							<CardBody>
								<div className="float-sm-right">
									{" "}{this.state.user_privileges.indexOf(133) !== -1 ? <Button
										color="primary"
										size="sm"
										id="cancel-orders"
										onClick={(e) => { this.cancelOrders(e, this.state.selectedIds) }}
									>
										Cancel Orders
									</Button> : ""}
									
								</div>
								<div className="mb-4 dropdown-edit">
									<FormGroup row>
										<Colxx lg="6">
											<Input
												type="text"
												name="table_search"
												id="table_search"
												placeholder="Search"
												value={this.state.search}
												onChange={e => this.setState({ search: e.target.value.toLowerCase() })}
											/>
										</Colxx>
									</FormGroup>
									{/* <Button
										color="primary"
										size="sm"
										id="cancel-orders"
										onClick={this.sendEmail}
										
									>
										Payment Reminder
									</Button>  */}
									<Row>
										<Colxx lg="12">
											<ReactTable
												data={data}
												columns={dataTableColumns}
												defaultPageSize={10}
												filterable={false}
												showPageJump={false}
												PaginationComponent={DataTablePagination}
												showPageSizeOptions={true}
												defaultSorted={[{ id: "send_on", desc: false }]}
											/>
										</Colxx>
									</Row>
								</div>
							</CardBody>
						</Card>
					</Colxx>
				</Row>

				<ViewOrderModal
					modalOpen={this.state.modalOpenViewOrder}
					toggleModal={this.toggleModalViewOrder}
					order_id={this.state.order_id}
					dataListRender={this.dataListRender}
				/>

				<ApproveOrderModal
					modalOpen={this.state.modalOpenApproveOrder}
					toggleModal={this.toggleModalApproveOrder}
					order_id={this.state.order_id}
					dataListRender={this.dataListRender}
				/>

				<EditInvoiceModal
					modalOpen={this.state.modalOpenEditInvoice}
					toggleModal={this.toggleModalEditInvoice}
					order_id={this.state.order_id}
					dataListRender={this.dataListRender}
				/>

				<CompleteOrderModal
					modalOpen={this.state.modalOpenCompleteOrder}
					toggleModal={this.toggleModalCompleteOrder}
					order_id={this.state.order_id}
					dataListRender={this.dataListRender}
				/>

				<RefundOrderModal
					modalOpen={this.state.modalOpenRefundOrder}
					toggleModal={this.toggleModalRefundOrder}
					order_id={this.state.order_id}
					dataListRender={this.dataListRender}
				/>

				<VoidOrderModal
					modalOpen={this.state.modalOpenVoidOrder}
					toggleModal={this.toggleModalVoidOrder}
					order_id={this.state.order_id}
					dataListRender={this.dataListRender}
				/>

				<InvoiceModal
					modalOpen={this.state.modalOpenInvoice}
					toggleModal={this.toggleModalInvoice}
					order_id={this.state.order_id}
				/>

				<PrintLabelModal
					modalOpen={this.state.modalOpenPrintLabel}
					toggleModal={this.toggleModalPrintLabel}
					order_id={this.state.order_id}
					modal_head={"Print Order Labels"}
					is_order_list={true}
				/>

			</Fragment>
		);
	}
}
