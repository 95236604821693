import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Input
} from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../common/CustomBootstrap";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE,DEFAULT_QTY_TYPES } from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import localforage from "localforage";
var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class AddVariationModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);	
	}

	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			
			document.getElementById("add-product-variation").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData(document.getElementById("addVariationForm"));
			formData.append('facility_id',facility_id);
			formData.append('product_id',this.props.product.id);
			formData.append('user_id',user_id);
			
			axios.post(REST_API_END_POINT+'inventory/add-variation',formData).then((res)=>{
				var data = res.data;
			
				
				if(data.status==1){						
					var api_message=document.getElementById("api_message_variations");
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
					
					document.getElementById("add-product-variation").disabled=false;
					this.props.toggleModal();
					this.props.dataListRender();
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-product-variation").disabled=false;
			});
		}
	}
	
	render() {
		const qty_type = DEFAULT_QTY_TYPES.find(r=>{ return r.value==this.props.product.qty_type });
		var product_type=1;
		if(this.props.product!=null){
			product_type=parseInt(this.props.product.product_type);
		}
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Add Variation
				</ModalHeader>
				<AvForm
					id="addVariationForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
				<ModalBody>
					<Row>
						<Colxx lg="3">
							<Label>Variation Name<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup >								
								<AvField
									id="variation_name"
									name="variation_name"
									type="text"
									autoComplete="off"
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										}
									}}
								/>
							</AvGroup>
						</Colxx>
					</Row>
					<Row className={product_type==2 && this.props.product.qty_type==3 ? "d-none":""}>
						<Colxx lg="3">
							<Label>Quantity<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="6">
							<AvGroup >								
								<AvField
									id="quantity"
									name="quantity"
									type="text"
									autoComplete="off"
									value={product_type==2 && this.props.product.qty_type==3 ? "1":null}
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
										number: {
											value: true,
											errorMessage: "This entry is invalid."
										},
										min:{
											value: "0",
											errorMessage: "Please enter a value greater than or equal to 0"
										}
									}}
								/>
							</AvGroup>
						</Colxx>
						<Colxx lg="3">
							<AvGroup >								
								<AvField
									id="variation_type"
									name="variation_type"
									type="select"
									value={this.props.product.qty_type==3 ? '3':this.props.product.qty_type.toString()}
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										}
									}}
								>
								{this.props.product.qty_type==3 ? <option value="3" key={3}>Unit</option>:(
									this.props.product.qty_type==4 ? <option value="4" key={4}>Litre</option>:<Fragment>
											{/*<option value="0" key={0} >Milligram</option>*/}
											<option value="1" key={1} >Gram</option>
											<option value="2" key={2} >Kilogram</option>
											{/*<option value="2" key={2} >OZ</option>*/}
										</Fragment>
									)
								}
								</AvField>
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="3 d-none">
							<Label>Sub Category</Label>
						</Colxx>
						<Colxx lg="9 d-none">
							<AvGroup >								
								<AvField
									id="sub_category_id"
									name="sub_category_id"
									type="select"
									value="0"
								>
								<option value="0" >-- Select Sub Category --</option>
								{this.props.sub_categories.map((row,index)=>{
									return <option value={row.value} key={index} >{row.label}</option>
								})}
								</AvField>
							</AvGroup>
						</Colxx>
					</Row>
					<Row className="">
						<Colxx lg="3">
							<Label>Cost per Unit<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup >								
								<AvField
									id="variation_value"
									name="variation_value"
									type="number"
									value="1"
									autoComplete="off"
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
										number: {
											value: true,
											errorMessage: "This entry is invalid."
										},
										min:{
											value: 0,
											errorMessage: "Please enter a value greater than or equal to 0"
										}
									}}
								/>
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="3">
							<Label>Price<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup >								
								<AvField
									id="variation_price"
									name="variation_price"
									type="number"
									autoComplete="off"
									value="1"
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
										number: {
											value: true,
											errorMessage: "This entry is invalid."
										},
										min:{
											value: 0,
											errorMessage: "Please enter a value greater than or equal to 0"
										}
									}}
								/>
							</AvGroup>
						</Colxx>
					</Row>
				
					{product_type==1 ? <Row>
						<Colxx lg="3">
							<Label>Amount of Flower<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup >								
								<AvField
									id="amount_of_flower"
									name="amount_of_flower"
									type="number"
									autoComplete="off"
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
										number: {
											value: true,
											errorMessage: "This entry is invalid."
										},
										min:{
											value: 0,
											errorMessage: "Please enter a value greater than or equal to 0"
										}
									}}
								/>
							</AvGroup>
						</Colxx>
					</Row>:""}
				</ModalBody>
				<ModalFooter>
					<Button color="primary"  id="add-product-variation" >
						<IntlMessages id="customer.add" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			   </AvForm>
			</Modal>
	  );
  }
};