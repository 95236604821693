import React, { Component, Fragment } from "react";
import { Row, Card, CardTitle, Form, Label, Input, Button, Container } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
//import Paginator from 'react-hooks-paginator';
import { ToastProvider } from "react-toast-notifications";

import { loginUser } from "../redux/actions";
import { Colxx } from "../components/common/CustomBootstrap";
import { projectTitle } from "../constants/defaultValues";
import HomeHeader from "../components/common/HomeHeader";
import HomeFooter from "../components/common/HomeFooter";

import { REST_API_END_POINT, dataEncryptKey, BASE_URL } from "../constants/defaultValues";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import MailChimpIntegration from "../components/front-end/MailChimpIntegration";
import { seo } from "../helpers/Utils";
import { SideSection, TestimonialSolo, StorePromo } from '../components/common/landingPage';

const dataCrypto = new SimpleCrypto(dataEncryptKey);

class Quality extends Component {

    constructor(props) {
        super(props);
        this.state = {
            template_data: null,
            alias_key: null
        };
        this.dataListRender = this.dataListRender.bind(this);
        seo({
            title: 'About | ' + projectTitle,
            metaDescription: 'Know our story'
        });
    }

    componentDidMount() {

        document.body.classList.add("background");
        document.body.classList.add("background-main-pages");
        var facility_id = localStorage.getItem('facility_id');
        if (facility_id) {
            this.dataListRender();
        } else {
            window.location = '/';
        }
    }
	
	componentDidUpdate() {
		var facility_id = localStorage.getItem('facility_id');
        if (facility_id) {
			if(this.state.alias_key!==this.props.match.params.alias_key){
				this.dataListRender();
			}
        } else {
            window.location = '/';
        }
    }
    componentWillUnmount() {
        document.body.classList.remove("background");
        document.body.classList.remove("background-main-pages");
    }

    dataListRender() {
        var facility_id = localStorage.getItem('facility_id');
        facility_id = dataCrypto.decrypt(facility_id);
        var data_upload = new FormData();
        data_upload.append('facility_id', facility_id);
        data_upload.append('template_id', this.props.match.params.alias_key);
        axios.post(REST_API_END_POINT + 'website/get-page-template-by-alias', data_upload).then((res) => {
            if (res.data.status == 1) {
				let data = res.data.data;
				seo({
					title: data.name+' | ' + projectTitle,
					metaDescription: 'Know our story'
				});
                this.setState({ alias_key:this.props.match.params.alias_key,template_data: res.data.data, blocking: false });
            } else {
				window.location="/error"
                this.setState({ blocking: false });
            }
        }).catch(e => {
            console.log("Addition failed , Error ", e)
            this.setState({ blocking: false });
        });
    }
	
	generateField(name,fields,index){
		if(name==="Quote"){
			let author = fields.find(r=>{ return r.key==='author' });
			let head = fields.find(r=>{ return r.key==='head' });
			let quote = fields.find(r=>{ return r.key==='quote' });
			return <TestimonialSolo 
						key={index}
						person={author.value}
						heading={head.value} >
						“{quote.value}”
					</TestimonialSolo>
		}else if(name==="Heading"){
			let head = fields.find(r=>{ return r.key==='head' });
			let sub_head = fields.find(r=>{ return r.key==='sub_head' });
			let buttonK = fields.find(r=>{ return r.key==='button' });
			let button_link = fields.find(r=>{ return r.key==='button_link' });
			if(head.value=="" && !(buttonK.value!='' && button_link.value!='')){
				return <div className='text-center mt-5  testim-solos' key={index}>
					<Container>
						<h5 className='h5' dangerouslySetInnerHTML={{ __html: sub_head.value }} ></h5>
					</Container>
				</div>
			}else{
				return <Container key={index}>
					<div className={'text-center mt-3 pt-5 '+(buttonK.value!='' && button_link.value!='' ? 'pb-5':'')}>
						{head.value.length<50 ? <h4 className='h1'>
							{head.value}
						</h4>:<h3>
							{head.value}
						</h3>}
						{sub_head.value!=="" ? <p>
                            {sub_head.value}
						</p>:""}
						{buttonK.value!='' && button_link.value!='' ? <a href={button_link.value} className="btn btn-secondary" >{buttonK.value}</a>:""}
					</div>
				</Container>
			}
		}else if(name==="Left Right Block"){
			let head = fields.find(r=>{ return r.key==='head' });
			let image_position = fields.find(r=>{ return r.key==='image_position' });
			let image_url = fields.find(r=>{ return r.key==='image_url' });
			let content = fields.find(r=>{ return r.key==='content' });
			let colorcbd = fields.find(r=>{ return r.key==='background' });
			let image_hover = fields.find(r=>{ return r.key==='image_hover' });
			const text_color = fields.find(r=>{ return r.key==='text_color' });
			let textColor = '#303030';
			if(text_color){
				textColor = text_color.value;
			}
			return <SideSection
					key={index}
					className='q-section'
					textLeft={image_position.value==='right'}
					img={`${BASE_URL}pages/${image_url.value}`}
					colorcbd={colorcbd.value}
					textColor={textColor}
					secondText={image_hover.value}
				>
					<div>
						<h3 className='h3'>
							{head.value}
						</h3>
						<div dangerouslySetInnerHTML={{ __html: content.value }} ></div>
					</div>
				</SideSection>
		}
	}

    render() {

        const { template_data } = this.state;
        var content_text = '';
        var banner_image = '';
        var page_name = '';
        var page_sub_title = '';
        if (template_data != null) {
            let content_text_row = (template_data.content.find(r => { return r.key == 'content_text' }));
            if (content_text_row != null) {
                content_text = content_text_row.value;
            }
            banner_image = template_data.thumbnail_image;
            page_name = template_data.name;
            page_sub_title = template_data.sub_title;
        }
        return (
            <Fragment>
                <ToastProvider placement="bottom-left">
                    <div className="main-pages-layout ">
                        <main className={this.props.match.params.alias_key.split('-').join('')}>
                            <HomeHeader />
							<div className="landing-root">
								{/* <Row className='q-section-main'>
									<Colxx xxs={12} md={6} className='p-5 d-flex justify-content-center align-items-center'>
										<div>
											<h1 className="h1">{page_name}</h1>
										</div>
									</Colxx>
									<Colxx xxs={12} md={6}>
										<div className='qual-header-img q-header-img' style={{background:`url('${BASE_URL}pages/${banner_image}')`}} />
									</Colxx>
								</Row> */}
								{console.log('Quill',template_data)}
								
								{template_data!=null ? template_data.content.map((row,index)=>{
									return this.generateField(row.name,row.fields,index)
								}):""}
							</div>
                            <HomeFooter />

                        </main>
                    </div>
                </ToastProvider>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ authUser }) => {
    const { user, loading } = authUser;
    return { user, loading };
};

export default Quality;
