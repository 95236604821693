import React, { Component, Fragment } from "react";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, FormGroup, Input } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import CheckedInMenu from "../../../components/pos/CheckedInMenu";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import ReactTable from "react-table";

import { REST_API_END_POINT, dataEncryptKey, JQUERY_MODULE } from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";
const dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Directory extends Component {

	constructor(props) {
		super(props);

		this.state = {
			customers: [],
			search: '',
			duration:''
		};

		this.checkIn = this.checkIn.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
	}

	componentDidMount() {
		this.dataListRender();
	}

	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id = dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id', facility_id);

		axios.post(REST_API_END_POINT + 'point-of-sale/get-customer-check-in', formData).then((res) => {
			var data = res.data;

			if (data.customers) {
				var customers = data.customers;
				// let duration=customers.hours+"Hours "+ customers.minutes+"Minutes "+customers.seconds+"Seconds "
					this.setState({
						customers: customers,
					});
				
				
			} else {
				this.setState({
					customers: [],
				});
			}
		}).catch(e => {
			console.log("Addition failed , Error ", e);
		});
	}

	checkIn(customer_id, checkIn, vehicle_id = 0) {
		var facility_id = localStorage.getItem('facility_id');
		facility_id = dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id', facility_id);
		formData.append('customer_id', customer_id);
		formData.append('vehicle_id', vehicle_id);
		if (checkIn == 1) {
			axios.post(REST_API_END_POINT + 'point-of-sale/customer-checkout', formData).then((res) => {
				var data = res.data;

				if (data.status == 1) {
					this.dataListRender();
				}
			}).catch(e => {
				console.log("Addition failed , Error ", e);
			});
		}
		else {

			axios.post(REST_API_END_POINT + 'point-of-sale/customer_checkin', formData).then((res) => {
				var data = res.data;

				if (data.status == 1) {
					this.dataListRender();
					// window.location = "/app/point-of-sale/pos/"+customer_id+'/'+vehicle_id;
				}
			}).catch(e => {
				console.log("Addition failed , Error ", e);
			});
			// window.location = "/app/point-of-sale/pos/"+customer_id+'/'+vehicle_id;
		}



	}

	render() {
		let data = this.state.customers;
		if (this.state.search) {
			data = data.filter(row => {
				// if(typeof row.vehicle_number=='undefined'){
				// 	row.vehicle_number="";
				// }


				return row.vendor_name.toLowerCase().includes(this.state.search) || row.admission_no.includes(this.state.search)
			})
		}
   
	  
		const dataTableColumns = [
			{
				Header: "Admission No",
				accessor: "admission_no",
				Cell: props => <p className="list-item-heading mb-0">{props.value}</p>
			},
			{
				Header: "Name",
				accessor: "vendor_name",
				Cell: props => <p className="text-muted mb-0">{props.value}</p>
			},
			/*{
				Header: "Vehicle Number",
				accessor: "vehicle_number",
				Cell: props => <p className="text-muted mb-0">{props.value}</p>
			},
			/*{
				Header: "License",
				accessor: "license",
				Cell: props => <p className="text-muted mb-0">{props.value}</p>
			},*/
			// {
			// 	Header: "Checked In",
			// 	accessor: "checked_in",
			// 	Cell: props => <Fragment>{props.value == 1 ? "Yes" : "No"}</Fragment>
			// },
			{
				Header: "Activity Duration",
				accessor: "duration",
				Cell: props => <p className="mb-0 text-danger">{props.value}</p>
			},
			{
				Header: "Action",
				accessor: "check_in",
				Cell: props => <Fragment> <Button color="success" size="xs" className="mb-2" onClick={() => { this.checkIn(props.original.vendor_id, props.original.checked_in) }}  >{props.value == '' ? (props.original.checked_in == 1 ? "Check Out" : "Check In") : <img src={props.value} className="img-responsive" />}</Button></Fragment>
			}
		];
		const now = moment();
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12" className="d-flex justify-content-center align-items-center" >
					   
						<Breadcrumb heading="menu.customer-lookup" match={this.props.match}  />
						
						<Separator className="mb-5" />
						<span className="ml-5">{now.format('YYYY-MM-DD hh:mm:ss A')}</span>
					</Colxx>
				</Row>

				<Row>
					<Colxx md="12">
						<Card>
							<CardBody>
								{/*<h4 className="text-danger text-center mb-4">Only customers with vehicles will be listed here</h4>*/}
								<div className="mb-4 dropdown-edit">
									<FormGroup row>
										<Colxx lg="12">
											<Input
												type="text"
												name="table_search"
												id="table_search"
												placeholder="Search"
												autoComplete="off"
												value={this.state.search}
												onChange={e => this.setState({ search: e.target.value.toLowerCase() })}
											/>
										</Colxx>
									</FormGroup>
									<Row>
										<Colxx lg="12">
											{this.state.search?<ReactTable
												data={data}
												columns={dataTableColumns}
												defaultPageSize={10}
												filterable={false}
												showPageJump={false}
												PaginationComponent={DataTablePagination}
												showPageSizeOptions={true}
												defaultSorted={[{ id: "send_on", desc: true }]}
											/>:""}
										</Colxx>
									</Row>
								</div>
							</CardBody>
						</Card>
					</Colxx>
				</Row>

				{/* <CheckedInMenu 
				dataListRender={this.dataListRender}
			/> */}
			</Fragment>
		);
	}
}
