import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input,
    DropdownItem,
	CustomInput, Badge,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddNewAccountsModal from "../../../containers/accounts/AddNewAccountsModal";
import EditAccountsModal from "../../../containers/accounts/EditAccountsModal";
import ReactTable from "react-table";
import moment from 'moment';
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class AccountManagement extends Component {
	
	constructor(props) {
		super(props);
	    this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {			 
			modalOpenAdd: false,
			modalOpenEdit: false,
			selectedIds:[],
			selectAll:0,
			account_id:0,
			account:[],
			search: '',
		};
		this.deleteItem = this.deleteItem.bind(this);
		this.toggleSelectAll = this.toggleSelectAll.bind(this);
	
	}
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.account.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
		
	deleteItem = (e,selectedIds,status)  => {
		if(selectedIds.length==0){
			swal("Warning!","Please select at least one expense","warning");
			return;
		}
		
		var msg="";
		var success_msg="";
		var failure_msg="";
        var msg_word="expense";
		
		if(selectedIds.length>1){
			msg_word="expense";
		}
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-account").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var formData = new FormData();
					formData.append('expense_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					
					axios.post(REST_API_END_POINT+'expenses/delete-expenses',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-account").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-account").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	activeItem=(e,account_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(account_id);
			
		}else{
		  var x=selectedIds.indexOf(account_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.account.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalEdit = (e,account_id=0) => {
		if(account_id!=0){
			this.setState({
				account_id: parseInt(account_id),
			});
		}
		this.setState({
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};
  
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'expenses/all_expenses',formData).then((res)=>{
			var data = res.data;
		
			if(data.expenses){
				this.setState({
					account: data.expenses,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					account: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	componentDidMount(){
	   this.dataListRender();
	}
  
  render() {
		let data = this.state.account;
	
		console.log('SEarch',this.state.search);
		
		if (this.state.search){
			
			
			data = data.filter(row => {
			
		
				return row.name.toLowerCase().includes(this.state.search) || row.spend_on.toLowerCase().includes(this.state.search) || row.added_by.toLowerCase().includes(this.state.search) || row.amount.toLowerCase().includes(this.state.search)
			})
		}
		
	const dataTableColumns = [		
	 	{
			Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
			accessor: "id",
			width:50,
			sortable: false,
			Cell: ( rowInfo )=> {
				return ( <FormGroup>
						<CustomInput type="checkbox" id={"coupon_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
					</FormGroup> );
			}
		},
		{
			Header: "Name",
			accessor: "name",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		},
		{
			Header: "Created On",
			accessor: "spend_on",
			Cell: props => <p className="list-item-heading">{ moment(props.value*1000).format("MM/DD/YYYY")}</p>
		},
		{
			Header: "Amount",
			accessor: "amount",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		},
		{
			Header: "Type",
			accessor: "type",
			Cell: props => <p className="list-item-heading">{props.value?props.value+(props.original.staff ? " ("+props.original.staff+")":""):""}</p>
		},
		{
			Header: "Created By",
			accessor: "added_by",
			Cell: props => <p className="text-muted">{props.value}</p>
		},	 
		{
			Header: "Updated By",
			accessor: "updated_by",
			Cell: props => <p className="text-muted">{props.value}</p>
		},	 	  
		{
			Header: "",
			accessor: "id",
			sortable: false,
			Cell: props => <Fragment><div className="action-btns w-9 w-sm-100"><ItemDropdown item={<DropdownMenu >
					<DropdownItem onClick={(e)=> this.toggleModalEdit(e,props.value) }>
						<span className="d-inline-block">Edit</span>
					</DropdownItem>
					{/* <DropdownItem>
						<NavLink to={"/app/account/view-account/"+props.value}>View</NavLink>
					</DropdownItem>				 */}
				  </DropdownMenu>} /></div>
				</Fragment> 
		}
	];
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.all-accounts" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4">
		   <Row>
				<Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx>
				<Colxx xxs="12">
					<div className="float-sm-right">
						{" "}<Button																		 
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalAdd}
							>
							  <IntlMessages id="pages.add-new" />							  
						</Button>																		
						   {" "}<Button
							color="primary"
							size="sm"
							className=""
							id="delete-account"
                            onClick={event =>this.deleteItem(event,this.state.selectedIds,2)} 						
						  >
							<IntlMessages id="pages.delete" />
					  </Button>
					</div>
					 
						  
					<div className="mb-4 dropdown-edit">						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
						  </Colxx>
						</FormGroup>			
						<ReactTable
						  data={data}
						  columns={dataTableColumns}
						  defaultPageSize={10}
						  filterable={false}
						  showPageJump={false}
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: false}]}
						/>
					</div>
					
			  </Colxx>
			</Row>
			{this.state.modalOpenAdd?<AddNewAccountsModal
				modalOpen={this.state.modalOpenAdd}
				toggleModal={this.toggleModalAdd}
				dataListRender = {this.dataListRender}
			/>:""}
			{this.state.modalOpenEdit?<EditAccountsModal
				modalOpen={this.state.modalOpenEdit}
				toggleModal={this.toggleModalEdit}
				account_id={this.state.account_id}
				dataListRender = {this.dataListRender}
			/>:""}
		</Card>
      </Fragment>
    );
  }
}
